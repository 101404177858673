<template>
  <div class="tools__page">
    <div class="content-container pt-0">
      <div class="content">
        <div
          class="landing-header"
          style="background-color: #f1353a; height: 240px"
        >
          <div
            class="content-band"
            style="margin: 50px auto; color: white; background-color: #f1353a; margin-top: 0; position: relative"
          >
            <div class="pagehead-title">
              <h1 style="width: 322px; padding-top: 40px;">Developer Guides</h1>
            </div>
          </div>
          <img
            src="../assets/images/banner/dev-portal-spotlight-tools.png"
            alt=""
            style="position: absolute;top: 0;right: 0; width: 500px; height: 240px"
          />
        </div>
        <div class="page flex-center">
          <section
            class="content-band flex-col"
            style="margin: 50px auto; margin-bottom: 0;"
          >
            <div style="flex">
              <h3 class="call-out-header semibold">Guides</h3>
              <p>
                Ever get stuck with a problem that you don't know how to solve
                regarding the IOP Platform? Check out our Developer Guides where
                you can find solutions, tips & tricks, etc.
              </p>
            </div>
          </section>

          <section class="mt-40">
            <div class="content-band flex-wrap even-cols gap-30 mt-0">
              <a class="tile hover-white gray-250" @click="handleWebFormsClick">
                <div class="tile_img">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 47.14 28.23"
                    class="cdn-icon"
                  >
                    <path
                      d="M18.93,20.15l-13.51,0A6,6,0,0,1,6,8.21h.56a5.8,5.8,0,0,1,11.41,1.5v.06h1a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-2V9.71a4.8,4.8,0,0,0-9.52-.9l-.08.4H6a5,5,0,0,0-.48,9.92l.5,0H18.93a4.19,4.19,0,0,0,3.47-1.84.51.51,0,0,1,.7-.14.5.5,0,0,1,.13.7A5.18,5.18,0,0,1,18.93,20.15Z"
                    />
                    <path
                      d="M31.3,28.21H22.19l.88-5.15h7.35Zm-7.93-1h6.74l-.54-3.15H23.91Z"
                    />
                    <path
                      d="M34,28.21H19.49a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H34a.5.5,0,0,1,.5.5A.51.51,0,0,1,34,28.21Z"
                    />
                    <path
                      d="M45.92,28.23H36.78A1.21,1.21,0,0,1,35.57,27V9.76a1.22,1.22,0,0,1,1.21-1.22h9.14a1.22,1.22,0,0,1,1.22,1.22V27A1.22,1.22,0,0,1,45.92,28.23ZM36.78,9.54a.21.21,0,0,0-.21.22V27a.21.21,0,0,0,.21.21h9.14a.21.21,0,0,0,.22-.21V9.76a.22.22,0,0,0-.22-.22Z"
                    />
                    <path
                      class="cls-1"
                      d="M46.64,12.78H36.07a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H46.64a.5.5,0,0,1,.5.5A.51.51,0,0,1,46.64,12.78Z"
                    />
                    <path
                      class="cls-1"
                      d="M44.78,17.38H37.89c-.18,0-.32-.23-.32-.5s.14-.5.32-.5h6.89c.18,0,.32.22.32.5S45,17.38,44.78,17.38Z"
                    />
                    <path
                      class="cls-1"
                      d="M44.78,20H37.89c-.18,0-.32-.23-.32-.5s.14-.5.32-.5h6.89c.18,0,.32.22.32.5S45,20,44.78,20Z"
                    />
                    <path
                      class="cls-1"
                      d="M46.64,25.12H36.07a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H46.64a.5.5,0,0,1,.5.5A.51.51,0,0,1,46.64,25.12Z"
                    />
                    <path
                      class="cls-1"
                      d="M33.24,24.06H12.83a1.22,1.22,0,0,1-1.21-1.22V21.62a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5v1.22a.21.21,0,0,0,.21.22H33.24a.5.5,0,0,1,.5.5A.5.5,0,0,1,33.24,24.06Z"
                    />
                    <path
                      class="cls-1"
                      d="M41.37,6.59a.5.5,0,0,1-.5-.5V1.92A.92.92,0,0,0,40,1H13.54a.92.92,0,0,0-.92.92.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5A1.92,1.92,0,0,1,13.54,0H40a1.92,1.92,0,0,1,1.92,1.92V6.09A.5.5,0,0,1,41.37,6.59Z"
                    />
                    <path
                      class="cls-1"
                      d="M31.78,16.49h-6A3.48,3.48,0,0,1,22.28,13a.5.5,0,1,1,1,0,2.48,2.48,0,0,0,2.47,2.47h6a.5.5,0,0,1,0,1Z"
                    />
                    <path
                      class="cls-1"
                      d="M31.29,18a.49.49,0,0,1-.35-.15.5.5,0,0,1,0-.71L32.07,16l-1.13-1.13a.5.5,0,0,1,.71-.71l1.48,1.49a.48.48,0,0,1,0,.7l-1.48,1.49A.51.51,0,0,1,31.29,18Z"
                    />
                    <path
                      class="cls-1"
                      d="M32.27,12.34a.5.5,0,0,1-.5-.5A2.48,2.48,0,0,0,29.3,9.36h-6a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h6a3.48,3.48,0,0,1,3.47,3.48A.5.5,0,0,1,32.27,12.34Z"
                    />
                    <path
                      class="cls-1"
                      d="M23.76,10.84a.47.47,0,0,1-.35-.15L21.93,9.22a.51.51,0,0,1,0-.71L23.41,7a.48.48,0,0,1,.7,0,.5.5,0,0,1,0,.71L23,8.86,24.11,10a.5.5,0,0,1,0,.71A.47.47,0,0,1,23.76,10.84Z"
                    />
                    <path
                      class="cls-1"
                      d="M3.38,14.65a.51.51,0,0,1-.5-.5A3.14,3.14,0,0,1,6,11a.5.5,0,0,1,.5.5A.5.5,0,0,1,6,12a2.14,2.14,0,0,0-2.14,2.14A.5.5,0,0,1,3.38,14.65Z"
                    />
                  </svg>
                </div>
                <div class="tile_content">
                  <h2>IOP Web Forms</h2>
                  <p>
                    IOP Web Forms are the State standards for using forms. We
                    use Formio as our form solution, which gives the user the
                    ability to create forms in a drag and drop fashion, send
                    automated emails based on form completion and much more.
                  </p>
                </div>
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// import Tile from "../components/Tile";
import Footer from "../components/Footer";

export default {
  name: "Tools",

  components: {
    // Tile,

    Footer,
  },
  data: function() {
    return {
      isFriendlyURLHovered: false,
      isCDNHovered: false,
    };
  },
  methods: {
    handleWebFormsClick: function() {
      this.$router.push("/guides/iop-web-forms");
    },
  },
};
</script>
<style>
.landing-header {
  position: relative;
}
.landing-header .conten-band {
  position: relative;
}
/* .landing-header .content-band::before {
  content: "";
  position: absolute;
  height: 240px;
  width: 553px;
  background-color: #f1353a;
  top: 0;
  left: -553px;
}
.landing-header .content-band::after {
  content: "";
  position: absolute;
  height: 240px;
  width: 553px;
  background-color: #f1353a;
  top: 0;
  right: 0;
} */
/* @media screen and (min-width: 1456px) {
  .landing-header .content-band::after {
    right: -553px;
  }
} */
.dev-portal a.tile.tile--tools:hover .tile_content {
  color: unset;
}
</style>
